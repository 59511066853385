import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Link from "gatsby-link"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { min, max } from "../utils/theme"

const Section = styled.section`
  padding: 2rem;

  min-height: 500px;
`
const StyledLink = styled(Link)`
  color: black;
`

const HeadingText = styled.h1`
  font-family: "Merriweather", serif;
  font-weight: normal;
  margin-bottom: 1rem;

  ${max("mini")(css`
    font-size: 18px;
  `)};

  ${min("small")(css`
    font-size: 22px;
  `)};

  ${min("small")(css`
    font-size: 30px;
  `)};

  ${min("large")(css`
    font-size: 40px;
  `)}
`

const Kiitos = () => (
  <Layout>
    <SEO title="Kiitos" />
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Section>
      <HeadingText>Kiitos, olemme yhteydessä mahdollisimman pian.</HeadingText>
      <StyledLink to="/">Takaisin etusivulle</StyledLink>
    </Section>
  </Layout>
)

export default Kiitos
